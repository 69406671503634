import { useState } from "react";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import { ApiOptions } from "~/old-app/types/api";
import { ProductsQueryParams } from "../product";
import {
  useCategoriesQuery,
  useCategoryQuery,
  useCategoriesTreeQuery,
} from "./category-api";

export const useCategories = (
  props?: ProductsQueryParams,
  options?: ApiOptions
) => {
  const { selectedService } = useConfigSlice();
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } = useCategoriesQuery(
    {
      ...props,
      service_id: selectedService,
      page,
    },
    { ...options, skip: !selectedService }
  );

  const categories = data?.response.data || [];
  const total = data?.response.meta?.total || 0;
  const hasMore = total !== categories.length;
  const fetchNextPage = () => setPage((prev) => prev + 1);
  const meta = data?.response?.meta;

  return {
    categories,
    total,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
    fetchNextPage,
    meta,
  };
};
export const useCategoriesTree = (
  props?: ProductsQueryParams,
  options?: ApiOptions
) => {
  const { selectedService } = useConfigSlice();
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } = useCategoriesTreeQuery(
    {
      ...props,
      service_id: selectedService,
      page,
    },
    { ...options, skip: !selectedService }
  );

  const categories = data?.response.data || [];
  const total = data?.response.meta?.total || 0;
  const hasMore = total !== categories.length;
  const fetchNextPage = () => setPage((prev) => prev + 1);
  const meta = data?.response?.meta;

  return {
    categories,
    total,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
    fetchNextPage,
    meta,
  };
};

export const useCategory = (id?: number, withChildren = true) => {
  const { data, isLoading, isFetching } = useCategoryQuery(
    { id, withChildren },
    { skip: !id }
  );
  return {
    category: data?.response.data,
    isLoading,
    isFetching,
  };
};

// export const useFaq = (id?: string) => {
//   const { data, isLoading } = useFaqQuery(id, { skip: !id });
//   return {
//     faq: data,
//     isLoading,
//   };
// };
// export const useFaqByPageName = (
//   pageName?: string,
//   courseInstanceId?: string
// ) => {
//   const { data, isLoading } = useFaqByPageNameQuery(
//     { pageName, courseInstanceId },
//     { skip: !pageName }
//   );
//   return {
//     faq: data,
//     isLoading,
//   };
// };
